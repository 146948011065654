import React from "react"
import Navbar2 from "../components/Layout Components/Nav2"
import Sidebar2 from "../components/Layout Components/Side2"
import { useState } from "react"
import Footer from "../components/Layout Components/Footer"
import Partners from "../components/Sections/Partners"
import { createGlobalStyle } from "styled-components"
import Seo from "../components/seo"

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`

export default function PartnersPage() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <head>
        <title>Partners | CARNIVAL</title>
        <meta
          name="description"
          content="ZAJIYAH Carnival is the First Smart Brand City of Pakistan. It is a one of its kind commercial real estate project."
        />
        <link rel="icon" href="/Logo Small.png" />
      </head>

      <main>
        <div>
          <Seo title="Partners" />
          <GlobalStyle />
          <Sidebar2 isOpen={isOpen} toggle={toggle} />
          <Navbar2 toggle={toggle} />
          <Partners />
        </div>
      </main>
      <Footer />
    </div>
  )
}
